<template>
  <div>
    <div v-if="list.length === 0">
      <div class="no-redeem">ไม่พบประวัติการแลก</div>
    </div>
    <div class="content-reward-list" v-else>
      <div
        class="m-2 content-reward-group"
        v-for="(item, index) in list"
        :key="index"
      >
        <b-button @click.prevent="seeDetail(item)">
          <b-row class="line-top py-2">
            <b-col cols="5">
              <b-img-lazy
                v-if="item.image_url"
                v-bind="mainProps"
                :src="item.image_url"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
              <b-img-lazy
                v-else
                v-bind="mainProps"
                :src="require('@/assets/images/default-image.png')"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
            </b-col>
            <b-col>
              <div class="title">{{ item.name }}</div>
              <div class="description mt-1">{{ item.detail }}</div>
              <div class="redeem-privilege">ใช้สิทธิพิเศษแล้ว</div>
            </b-col>
          </b-row>
          <b-row class="px-2">
            <b-col cols="7" class="py-2">
              <div class="until-date">
                วันที่หมดอายุ: {{ dateFormat(item.expired_date) }}
              </div>
            </b-col>
            <b-col cols="5" class="py-2 text-right">
              <div class="until-date">{{ item.status_name }}</div>
            </b-col>
          </b-row>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    list: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      }
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },
    seeDetail(item) {
      if (item.is_previlege_coupon) {
        this.$router.push(`/privilegehistory/${item.privilege_id}`);
      } else {
        this.$router.push(`/couponhistory/${item.promotion_id}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-reward-list {
  background-color: #fff;
   margin: 1rem;
  border-radius: 5px;
  min-height: 100vh;
}
.total-point {
  border-bottom: 1px solid gray;
}
.point {
  font-size: 14px;
}
.totalpoint-font {
  font-weight: 600;
  font-size: 18px;
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-reward-group {
  background-color: #f0f0f0;
}
.title {
  font-size: 16px;
  font-weight: 600;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.description {
  color: #5f5f5f;
  font-size: 13px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.insert-redeem {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
}
.until-date {
  font-size: 12px;
}
.status-false {
  opacity: 0.6;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  padding: 5px 0;
  // border-bottom: 1px dashed rgb(170, 170, 170);
}
.point {
  font-size: 14px;
  color: green;
}
.no-redeem {
  color: #fff;
  text-align: center;
  padding: 20px 0;
}
.redeem-privilege {
  color: red;
  font-size: 11px;
  font-weight: 600;
}
::v-deep .btn {
  color: unset;
  background-color: transparent;
  border-color: transparent;
  text-align: left;
  padding: 0;
}
</style>