<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div></div>
      <b-row class="content-text-profile">
        <b-col class="text-right position-enter-code">
          <div class="icon-redeem text-center" @click="getRedeemCode">
            <font-awesome-icon icon="gift" />
          </div>
        </b-col>
      </b-row>
      <div>
        <div class="title-privilege">
          <b-tabs v-model="tabIndex">
            <b-tab title="ทั้งหมด">
              <PrivilegeListComponent :list="privilegeList" />
            </b-tab>
            <b-tab title="ประวัติการแลก">
              <PrivilegeHistory :list="privilegeHistory" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <ModalRedeemCode ref="ModalRedeemCode" @hideSuccess="refreshPage" />
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import PrivilegeListComponent from "@/components/privilege/PrivilegeListComponent";
import OtherLoading from "@/components/other/OtherLoading";
import PrivilegeHistory from "@/components/privilege/detail/PrivilegeHistory";
import ModalRedeemCode from "@/components/profile/modal/ModalRedeemCode";
export default {
  components: {
    Breadcrumb,
    PrivilegeListComponent,
    OtherLoading,
    PrivilegeHistory,
    ModalRedeemCode
  },

  async created() {
    if (this.$route.query) {
      if (parseInt(this.$route.query.tabIndex) === 1) {
        this.tabIndex = 1;
      }
    }
    await this.getCouponList();
    await this.getCouponHistory();
  },
  data() {
    return {
      breadcrumbList: [
        {
          text: "สิทธิพิเศษ",
          to: "/profile"
        }
      ],
      privilegeList: {},
      isLoading: true,
      notFoundData: false,
      privilegeHistory: {},
      tabIndex: 0
    };
  },
  methods: {
    getRedeemCode: async function () {
      await this.$refs.ModalRedeemCode.show();
    },
    async getCouponList() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Privilege/privilege_list`)
        .then(async data => {
          if (data.result == 1) {
            this.privilegeList = data.detail;
          }
        });
    },
    async getCouponHistory() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Privilege/privilege_history`)
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.privilegeHistory = data.detail;
          }
        });
    },
    refreshPage() {
      window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-black {
  background-color: #000;
  min-height: 100vh;
}
.remark {
  color: #000;
  font-weight: 600;
  font-size: 18px;
}
.title {
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 80px;
}
.icon-history-custom {
  color: #fff;
  text-align: right;
  margin-top: -31px;
  margin-bottom: 5px;
  right: 15px;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: 600;
  border-bottom: 4px solid #fff !important;
  background-color: #000;
  color: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
}
::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
::v-deep .nav-tabs {
  background-color: #000;
  border-bottom: none;
}
::v-deep a {
  color: #fff;
}
.position-enter-code {
  position: absolute;
  top: 20px;
}
.icon-redeem {
  position: absolute;
  top: 70px;
  right: 0;
  border-radius: 5px;
  color: #fff;
  z-index: 2;
}
</style>
