<template>
  <div>
    <div v-if="list.length > 0" class="content-privilege">
      <div v-for="(item, index) in list" :key="index">
        <div
          :class="[
            'm-2',
            chkExpiredDate(item.expired_date)
              ? 'content-privilege-group-fade'
              : 'content-privilege-group'
          ]"
        >
          <b-row class="line-top">
            <b-col cols="5">
              <b-row class="ft-12 my-1" v-if="item.is_previlege_coupon">
                <b-col cols="2" class="p-0 text-center gift-content">
                  <div class="privilege-gift">
                    <font-awesome-icon icon="gift" class="" />
                  </div>
                </b-col>
                <b-col cols="10" class="px-1">
                  <b-button
                    class="tag tag-second"
                    @click.prevent="
                      $router.push(`/privilege/set/${item.privilege_id}`)
                    "
                  >
                    <div class="line-clamp1 text-left">
                      {{ item.privilege_name }}
                    </div>
                  </b-button>
                </b-col>
              </b-row>
              <div>
                <b-img-lazy
                  v-if="item.image_url"
                  v-bind="mainProps"
                  :src="item.image_url"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
                <b-img-lazy
                  v-else
                  v-bind="mainProps"
                  :src="require('@/assets/images/default-image.png')"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
              </div>
            </b-col>
            <b-col>
              <div class="title line-clamp-2">{{ item.name }}</div>
              <div class="description line-clamp-2">{{ item.detail }}</div>
              <div
                v-if="chkExpiredDate(item.expired_date)"
                class="my-2 redeem-privilege"
              >
                วันหมดอายุ:
                {{ item.expired_date ? dateFormat(item.expired_date) : "-" }}
              </div>
            </b-col>
          </b-row>
          <b-row class="px-2">
            <b-col cols="7" class="py-2">
              <div class="expired-date">
                วันหมดอายุ:
                {{ item.expired_date ? dateFormat(item.expired_date) : "-" }}
              </div>
            </b-col>
            <b-col cols="5" class="p-0 text-right">
              <b-button
                class="btn-redeem"
                :disabled="chkExpiredDate(item.expired_date)"
                @click.prevent="seeDetailPrivilege(item.promotion_id)"
              >
                <div class="see-all w-100">ดูรายละเอียด</div>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center py-3 notfound-data">ไม่พบข้อมูลสิทธิพิเศษ</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {
    moment
  },
  props: {
    list: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 300,
        height: 300,
        class: ""
      }
    };
  },
  methods: {
    seeDetailPrivilege(id) {
      this.$router.push("/privilege/" + id);
    },
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },
    chkExpiredDate(date) {
      const currentDate = new Date();
      const privilegeDate = new Date(date);
      const sameDate = currentDate.getTime() > privilegeDate.getTime();
      return sameDate;
    }
  }
};
</script>

<style lang="scss" scoped>
.line-clamp1 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.ft-12 {
  font-size: 12px;
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-privilege-group {
  background-color: #f0f0f0;
}
.content-privilege-group-fade {
  background-color: #f0f0f0;
  opacity: 0.5;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  padding: 5px 0;
}
.list-pri-content {
  background-color: #fff;
  margin: 1rem;
  border-radius: 5px;
  padding: 10px 0;
}
.title {
  font-size: 16px;
  font-weight: 600;
}
.description {
  font-size: 13px;
  color: #4b4b4b;
}
.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.expired-date {
  font-size: 12px;
}
.see-all {
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
}
.border-r {
  border-right: 2px dashed #d7d7d7;
}
.radius-bd {
  border-radius: 5px;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.redeem-privilege {
  color: red;
  font-size: 11px;
  font-weight: 600;
}
.tag-second {
  color: #000;
  background-color: #fcbd00;
  border: 1px solid #fcbd00;
  font-size: 12px;
  padding: 0 5px;
}
.tag {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  border-radius: 0px;
}
.privilege-gift {
  background-color: #fcbd00;
  border: 1px solid #fcbd00;
}
.gift-content {
  margin: auto 0px;
}
.content-privilege {
  background-color: #fff;
  margin: 1rem;
  border-radius: 5px;
  padding: 1px;
  min-height: 100vh;
}
.notfound-data {
  color: #fff;
  text-align: center;
  padding: 50px 0;
}
</style>
